// Import all of Bootstrap's CSS
// scss-docs-start color-variables

$black: #000;
$blue: #42A5F5;
$cyan: #00BCD4;
$gray-100: #E8ECF4;
$green: #66BB6A;
$indigo: #3730a3;
$lightgreen: #8BC34A;
$orange: #FF7043;
$pink: #EC407A;
$purple: #7E57C2;
$red: #DC3545;
$teal: #26A69A;
$yellow: #FFB300;
$violet: #AB47BC;
$bluegrey: #78909C;
$brown: #8D6E63;
$darkgray: #1E232C;
$white: #fff;



$primary: #08A698;
$secondary: $pink;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: #1E1E1E;

$font-family-base: 'Sora', sans-serif;
$headings-font-family: 'Poppins', sans-serif;


$headings-color: $dark;

$body-bg: $white;
$body-color:$black;
$container-padding-x: 48px;
$body-color-dark: $red;

$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 6,
  7: $spacer * 7,
  8: $spacer * 8,
  9: $spacer * 9,
  10: $spacer * 10,
) !default;


@import "node_modules/swiper/swiper";
@import "node_modules/bootstrap/scss/bootstrap";